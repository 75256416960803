/** @jsx jsx */
import { jsx, Link as TLink, Button } from "theme-ui";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SocialIcon = ({ iconClass, iconName }) => (
  <FontAwesomeIcon
    icon={[iconClass, iconName]}
    sx={{
      fontSize: `1.5rem`,
      mx: `.5rem`,
      color: `secondary`,
      transition: "color 0.3s ease",
      "&:hover, &:focus": { color: `primary` },
    }}
  />
);

export default SocialIcon;