/** @jsx jsx */
import { jsx, Link as TLink } from "theme-ui";

// eslint-disable-next-line no-unused-vars
import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";

const HeaderExternalLinks = () => {
  const styles = { px: 2, fontFamily: "heading", fontSize: [2] };
  const resumeQuery = useStaticQuery(graphql`
  {
    allFile(filter: {absolutePath: {regex: "/lorenjiang_resume.*\\.pdf/"}}, sort: {order: DESC, fields: absolutePath}) {
      edges {
        node {
          id
          absolutePath
          publicURL
        }
      }
    }
  }
  
  `);

  return (
    <>
      <TLink to="/about" as={Link} sx={styles}>
        about
      </TLink>
      <TLink to="/projects/software" as={Link} sx={styles}>
        projects
      </TLink>
      {/* <TLink to="/posts" as={Link} sx={styles}>
        posts
      </TLink> */}
      {/* <TLink to="/photos" as={Link} sx={styles}>
        photos
      </TLink> */}
      <TLink
        href={resumeQuery.allFile.edges[0].node.publicURL}
        as="a"
        sx={styles}
      >
        resume
      </TLink>
    </>
  );
};

export default HeaderExternalLinks;
