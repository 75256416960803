/** @jsx jsx */
import { css, jsx, Link as TLink } from "theme-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Link } from "gatsby";
import React, { Component } from "react";

class UserLinks extends Component {
  getLinkElements() {
    const { config } = this.props;
    const { userLinks } = config;
    const { labeled } = this.props;
    return userLinks.map((link) => (
      <TLink href={link.url} as="a" key={link.label}>
        <FontAwesomeIcon
          icon={[link.iconClass, link.iconName]}
          sx={{ fontSize: `1.5rem`, mx: `.5rem`, color: `secondary` }}
        />
      </TLink>
      // <a href={link.url} id={link.url}>
      //   <button type="button" key={link.label}>
      //     {labeled ? link.label : ""}
      //   </button>
      // </a>
    ));
  }

  render() {
    const { userLinks } = this.props.config;
    if (!userLinks) {
      return null;
    }
    return (
      <div
        className="user-links"
        css={`
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          max-width: 100%;
        `}
      >
        {this.getLinkElements()}
      </div>
    );
  }
}

export default UserLinks;
