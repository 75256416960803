/** @jsx jsx */
import { Link } from "gatsby";
import { jsx } from "theme-ui";
import config from "../../../data/SiteConfig";

const HeaderLogo = () => {
  // feel free to change to your own logo
  // by default will be the first initials of config.siteTitle
  const siteTitleInitials = config.siteTitle
    .split(/(\s+)/)
    .filter((e) => e.length > 1)
    .map((word) => word[0])
    .reduce((a, b) => a.toLowerCase() + b.toLowerCase(), "");
  const DefaultLogo = ({ initials }) => (
    <span
      sx={{
        my: 0,
        fontFamily: `heading`,
        fontWeight: `medium`,
        fontSize: [2, 3, 4],
        padding: `5px 2.5px`,
        position: "relative",
        "::before": {
          content: '""',
          position: `absolute`,
          zIndex: -1,
          top: `-5px`,
          left: `-13px`,
          right: `-7px`,
          bottom: `2px`,
          border: `2px solid`,
          transform: `translate(0px, 0px)`,
          transition: `transform 0.2s ease`,
        },
        "::after": {
          content: '""',
          position: `absolute`,
          zIndex: -1,
          top: `2px`,
          left: `-6px`,
          right: `-14px`,
          bottom: `-5px`,
          border: `2px solid`,
          transform: `translate(0px, 0px)`,
          transition: `transform 0.2s ease`,
        },
        ":hover::before": {
          transform: `translate(3.5px, 3.5px)`,
        },
        ":hover::after": {
          transform: `translate(-3.5px, -3.5px)`,
        },
        ":hover": {
          color: "primary",
        },
      }}
    >
      {initials}
    </span>
  );
  return (
    <Link
      to="/"
      aria-label={`${config.siteTitle} - Back to home`}
      sx={{ color: `heading`, textDecoration: `none` }}
    >
      <span sx={{ margin: 10 }}>
        <DefaultLogo initials={siteTitleInitials} />
      </span>
    </Link>
  );
};

export default HeaderLogo;
