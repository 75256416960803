/** @jsx jsx */
import { jsx, Container, Box } from "theme-ui";
// eslint-disable-next-line no-unused-vars
import React from "react";
import Helmet from "react-helmet";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faRedditAlien,
  faLinkedinIn,
  faFacebookF,
  faLinkedin,
  faInstagram,
  faTwitter,
  faGithub,
  faReddit,
  faFacebook,
  faTelegram,
  faTelegramPlane,
} from "@fortawesome/free-brands-svg-icons";
import {
  faDatabase,
  faTools,
  faCode,
  faEnvelope,
  faPaperPlane,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";

import config from "../../data/SiteConfig";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";

// import "./index.css";

library.add(
  faRedditAlien,
  faTelegramPlane,
  faLinkedinIn,
  faFacebookF,
  faInstagram,
  faLinkedin,
  faTwitter,
  faGithub,
  faReddit,
  faFacebook,
  faTelegram,
  faDatabase,
  faTools,
  faCode,
  faEnvelope,
  faPaperPlane,
  faExternalLinkAlt
);

const MainLayout = (props) => {
  const { children, style } = props;
  return (
    <Container sx={style}>
      <Helmet>
        <meta name="description" content={config.siteDescription} />
        <html lang="en" />
        <link
          rel="shortcut icon"
          href="/favicon_io/favicon.ico"
          type="image/x-icon"
        />
        <link rel="icon" href="/favicon_io/favicon.ico" type="image/x-icon" />
      </Helmet>
      <Header />
      <Box sx={{ minHeight: `50vh` }}>{children}</Box>
      <Footer />
    </Container>
  );
};

export default MainLayout;
