/** @jsx jsx */
import { jsx, useColorMode } from "theme-ui";
import { Flex } from "@theme-ui/components";
import ColorModeToggle from "./ColorModeToggle";
import Logo from "./HeaderLogo";
import HeaderExternalLinks from "./HeaderExternalLinks";

const Header = () => {
  const [colorMode, setColorMode] = useColorMode();
  const isDark = colorMode === `dark`;
  const toggleColorMode = (e) => {
    e.preventDefault();
    setColorMode(isDark ? `light` : `dark`);
  };

  return (
    <header sx={{ mb: [2, 3] }}>
      <Flex sx={{ alignItems: `center`, justifyContent: `space-between` }}>
        <Logo />
        <ColorModeToggle isDark={isDark} toggle={toggleColorMode} />
      </Flex>
      <div
        sx={{
          boxSizing: `border-box`,
          display: `flex`,
          variant: `dividers.bottom`,
          alignItems: `center`,
          justifyContent: `flex-end`,
          mt: 3,
          color: `secondary`,
          a: { color: `secondary`, ":hover": { color: `heading` } },
          flexFlow: `wrap`,
        }}
      >
        <HeaderExternalLinks />
      </div>
    </header>
  );
};

export default Header;
