const userInfo = {
  firstName: "Loren",
  lastName: "Jiang",
  email: "loren.jiang@gmail.com",
  githubUsername: "loren-jiang",
  twitterUsername: "",
  instagramUsername: "teawithloren",
  userLocation: "Oakland, CA",
};

const config = {
  siteTitle: `${userInfo.firstName} ${userInfo.lastName}`, // Site title.
  siteTitleShort: "lorenjiang.com", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: `${userInfo.firstName} ${userInfo.lastName}`, // Alternative site title for SEO.
  siteLogo: "/favicon_io/android-chrome-512x512.png", // Logo used for SEO and manifest.
  siteUrl: "https://lorenjiang.com", // Domain of your website without pathPrefix.
  envFilePath: ".env.dev", // File path of development env file
  pathPrefix: "/", // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  siteDescription: "personal website for Loren Jiang", // Website description used for RSS feeds/meta description tag.
  siteRss: "/rss.xml", // Path to the RSS file.
  siteRssTitle: "Loren Jiang RSS feed", // Title of the RSS feed
  siteFBAppID: "1825356251115265", // FB Application ID for using app insights
  googleAnalyticsID: "UA-47311644-5", // GA tracking ID.
  githubUsername: userInfo.githubUsername,
  githubReposToShow: ["misoboop", "xtal-classifier", "orso"],
  disqusShortname: "TODO", // Disqus shortname.
  dateFromFormat: "YYYY-MM-DD", // Date format used in the frontmatter.
  dateFormat: "DD/MM/YYYY", // Date format for display.
  postsPerPage: 5, // Amount of posts displayed per listing page, prebuilt with graphql.
  // If 0, paginated pages are not prebuilt and instead single posts page with dynamic filtering / pagination.
  userName: "Loren Jiang", // Username to display in the author segment.
  userEmail: userInfo.email, // Email used for RSS feed's author segment
  userTwitter: "", // Optionally renders "Follow Me" in the UserInfo segment.
  userLocation: "Oakland, CA", // User location to display in the author segment.
  userSkills: [
    // Backend skills
    {
      name: "Django",
      category: "backend",
    },
    {
      name: "REST",
      category: "backend",
    },
    {
      name: "PostgreSQL",
      category: "backend",
    },
    {
      name: "Python",
      category: "backend",
    },
    // Frontend skills
    {
      name: "JavaScript (ES6)",
      category: "frontend",
    },
    {
      name: "React",
      category: "frontend",
    },
    {
      name: "Redux",
      category: "frontend",
    },
    {
      name: "Node",
      category: "frontend",
    },
    // Build tools
    {
      name: "Git",
      category: "build",
    },
    {
      name: "Docker",
      category: "build",
    },
    {
      name: "CircleCI",
      category: "build",
    },
    // {
    //   name: "WebPack",
    //   category: "build",
    // },
  ],
  userDescription:
    "Yeah, I like animals better than people sometimes... Especially dogs. Dogs are the best. Every time you come home, they act like they haven't seen you in a year. And the good thing about dogs... is they got different dogs for different people.", // User description to display in the author segment.
  // Links to social profiles/projects you want to display in the author segment/navigation bar.
  userLinks: [
    {
      label: "Instagram",
      url: `https://www.instagram.com/${userInfo.instagramUsername}/`,
      iconClass: "fab",
      iconName: "instagram",
    },
    // {
    //   label: "Twitter",
    //   url: `https://twitter.com/${userInfo.twitterUsername}`,
    //   iconClass: "fab",
    //   iconName: "twitter",

    // },
    {
      label: "Github",
      url: `https://github.com/${userInfo.githubUsername}`,
      iconClass: "fab",
      iconName: "github",
    },
    {
      label: "LinkedIn",
      url: `https://www.linkedin.com/in/loren-jiang/`,
      iconClass: "fab",
      iconName: "linkedin-in",
    },
  ],
  copyright: "Copyright © 2020", // Copyright string for the footer of the website and RSS feed.
  themeColor: "#2c7a7b", // Used for setting manifest and progress theme colors.
  backgroundColor: "#fff", // Used for setting manifest background color.
};

// Validate

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === "/") {
  config.pathPrefix = "";
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, "")}`;
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === "/")
  config.siteUrl = config.siteUrl.slice(0, -1);

// Make sure siteRss has a starting forward slash
if (config.siteRss && config.siteRss[0] !== "/")
  config.siteRss = `/${config.siteRss}`;

module.exports = config;
