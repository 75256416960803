/** @jsx jsx */
import { jsx, Link as TLink, Button } from "theme-ui";
import React, { Component } from "react";
import SocialIcon from "../../components/Icons/SocialIcon";

import { Link } from "gatsby";
import UserLinks from "../UserLinks/UserLinks";
// import "./Footer.css";
import config from "../../../data/SiteConfig";

class Footer extends Component {
  render() {
    const url = config.siteRss;
    const { copyright } = config;
    if (!copyright) {
      return null;
    }
    return (
      <footer className="footer">
        <div sx={{ variant: `dividers.bottom`, mb: 3 }} />
        <div
          css={`
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            max-width: 100%;
          `}
        >
          {config.userLinks.map((link) => (
            <TLink href={link.url} as="a" key={link.label}>
              <SocialIcon iconClass={link.iconClass} iconName={link.iconName} />
            </TLink>
          ))}
        </div>
        {/* <div
          css={`
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            max-width: 100%;
          `}
        >
          {"built with gatsby \& ☕"} 
        </div> */}
        {/* <UserLinks config={config} labeled /> */}
        {/* <div className="notice-container">
          <h4>{copyright}</h4>

          <TLink to={url} as={Link} sx={{ color: "text" }}>
            Subscribe
          </TLink>
          <h4>Made with Gatsby.js</h4>
        </div> */}
      </footer>
    );
  }
}

export default Footer;
